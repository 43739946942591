import "core-js/modules/es.array.push.js";
import { checkMSGCode, getMSGCode } from '@api/expense';
import { setStorage } from '@utils/storage';
export default {
  name: 'verification',
  components: {},
  props: {},
  data() {
    return {
      form: {},
      verificationCode: '',
      // 收到的验证码
      countdown: true,
      // 验证码的状态，默认显示发送验证码
      count: '',
      // 60秒倒计时
      timer: null,
      text: '发送验证码'
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getCode() {
      if (!this.timer) {
        getMSGCode({
          phone: this.form.phone
        }).then(res => {
          let result = res.data;
          if (result.code === '10000') {
            const TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.countdown = false;
            this.text = '重新发送';
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.countdown = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.$toast(result.msg);
          }
        });
      }
    },
    onSubmit() {
      checkMSGCode({
        ...this.form
      }).then(res => {
        let result = res.data;
        if (result.code === '10000') {
          let {
            type
          } = this.$route.query;
          let {
            id
          } = result.data;
          setStorage('tenantId', id, 'localStorage');
          this.$router.push({
            name: 'expenseInfo',
            query: {
              tenantId: id,
              type
            }
          });
        }
      });
    }
  }
};