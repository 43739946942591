var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container"
  }, [_c('van-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticStyle: {
      "margin": "20px 0"
    }
  }, [_c('van-field', {
    attrs: {
      "name": "租客姓名",
      "label": "租客姓名",
      "placeholder": "请输入租客姓名",
      "rules": [{
        required: true,
        message: '租客姓名不能为空'
      }]
    },
    model: {
      value: _vm.form.userName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "身份证后四位",
      "label": "身份证后四位",
      "placeholder": "请输入身份证后四位",
      "rules": [{
        required: true,
        message: '身份证后四位不能为空'
      }]
    },
    model: {
      value: _vm.form.idCardLast,
      callback: function ($$v) {
        _vm.$set(_vm.form, "idCardLast", $$v);
      },
      expression: "form.idCardLast"
    }
  })], 1), _c('div', {
    staticStyle: {
      "margin": "50px 20px"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("下一步")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };